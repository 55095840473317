/* global window, jQuery */

require('core-js/fn/promise');
require('core-js/fn/object/values');
require('bootstrap/scss/bootstrap.scss');
require('public/css/main.scss');
require('owl.carousel/dist/assets/owl.carousel.css');

const Geolocation = require('public/js/api/geolocation')({ window });
const get = require('lodash/get');

// Setup our faux ajax handler so plugin can take over if necessary
window.$ajax = window.$ajax || jQuery.ajax;

// Assign jquery to our internal rp$ variable
// In the plugin case, be more careful about not clobbering existing $'s
if (window.__IS_WEBSTORE_PLUGIN) {
	window.rp$ = jQuery.noConflict();
} else {
	window.rp$ = jQuery;
}

// Set up global RP app object
window.rp_app = require('public/js/app');
window.rp_app.activeMakes = [];
window.rp_app.priceFormat = function(price, currencyCode) {
	// Calling Number Formatter here to get the (selected) locale based Formatter
	const { getNumberFormatter } = require('public/js/lib/format-helper');
	const NumberFormat = getNumberFormatter();
	if(currencyCode && currencyCode !== 'USD') {
		return `${NumberFormat.currency(price, currencyCode)}`;
	}
	return `${NumberFormat.currency(price)}`;
};
window.rp_app.request = require('public/js/lib/request');

if (get(window, 'tracking.adobe.adobe_dtm_enabled') && get(window, 'tracking.adobe.adobe_dtm_script_source')) {
	require('public/js/lib/digital-data');
} else {
	window.digitalData = {};
}

// When NOT in plugin mode...
if (!window.__IS_WEBSTORE_PLUGIN) {
	// Handle Error events
	window.addEventListener('error', window.rp_app.logger.handleErrorEvent, true);
	// Expose normal jquery globals
	window.jQuery = window.$ = window.rp$;
}

// Load 3rd Party Plugins
// 3rd party plugins can rely on gobal window.jQuery object, which we aren't guaranteed to have
(function (RPjQuery) {
	const tempJQ = window.jQuery; // Step 1: Take current jquery global and associate with a temp var
	window.jQuery = RPjQuery;     // Step 2: Replace global jquery object with rp$

	// BEGIN 3rd Party Plugin Load
	// require('select2/dist/js/select2.min.js');
	require('public/js/listeners/get-owl-carousel');

	require('public/js/listeners/get-bootstrap');
	// END 3rd Party Plugin load

	window.jQuery = tempJQ;       // Step 3: Restore original jquery global
})(window.rp$);

// JQuery Plugins
require('public/js/elements/email-addresses');
require('public/js/elements/overlay-spinner');
// Auto suggest
require('public/js/components/input-suggestions');
// Search Facets
require('public/js/components/search-facets');

const searchBox = require('public/js/components/search-box'); //TODO CODE_SPLIT HERE
const $onReady = require('public/js/lib/on-ready');
const lazyLoad = require('public/js/lib/image-lazy-load')();
const flyouts = require('public/js/elements/flyouts');
const paypalButtonHandler = require('public/js/components/paypal-button-handler');

const onReady = function () {
	const isIframedPage = get(rp_app, 'data.plugin_iframe_page') || false;

	/* Attach Lazy Load Intersection To Images/BG Images
	.*-----------------------------------------*/
	lazyLoad.attach();
	flyouts.attachEvents();

	require('public/js/listeners/get-cart');
	require('public/js/listeners/get-wishlist');
	require('public/js/listeners/get-vehicle-picker');

	/* Fix email address
	.*-----------------------------------------*/
	rp$('.email-address').emailAddress();

	searchBox.setupSearchBox();

	/* Add Paypal Smart buttons and click events
	.*-----------------------------------------*/
	paypalButtonHandler.initPaypalButtons();

	// Set browser location (contains lat/long only) if ip-location not cached [Currently Lead-Gen Only]
	// NOTE: On iframed pages, this code doesn't run (is handled by parent)
	if (!isIframedPage) {
		Geolocation.setBrowserLocation();
	}

	// function to check parents of given child has z-index style or not
	function parentsHaveZIndex(child) {
		let parents = child.parents();
		for (let i = 0; i < parents.length; i++) {
			if (rp$(parents[i]).css('z-index').length > 0 && rp$(parents[i]).css('z-index') !== 'auto') {
				return true;
			}
		}
		return false;
	}

	// check whether any of parents of #rp_plugin has z-index value
	// if parent has z-index value then append .modal-backdrop to
	// #rp_webstore when showing any #rp_webstore modal
	// This is to fix modal backdrop overlay display issue.
	if (window.__IS_WEBSTORE_PLUGIN && parentsHaveZIndex(rp$('#rp_plugin'))) {
		rp$('body').on('shown.bs.modal', '#rp_webstore .modal', function (e) {
			e.stopPropagation();
			rp$('.modal-backdrop').appendTo(rp$('#rp_webstore')).addClass('plugin-modal-backdrop');
			rp$(this).addClass('plugin-modal');
		});
	}
};

module.exports = () => {
	$onReady({fn: onReady, rp$, window, label: 'main'});
};
