/* global window */
'use strict';
const hoverintent = require('hoverintent');

var flyouts = {
	timers: {},
	getFlyout: function ($flyoutLink) {
		var $flyout;
		var flyoutId = $flyoutLink.attr('data-flyout');
		var flyoutSelector = (flyoutId) ? '.flyout[data-flyout=' + flyoutId + ']' : '.flyout';

		$flyout = $flyoutLink.siblings(flyoutSelector);
		if (!$flyout.length) {
			$flyout = $flyoutLink.parent().parent().find(flyoutSelector);
		}

		return $flyout;
	},
	getLink: function ($flyout) {
		var $flyoutLink;
		var flyoutId = $flyout.attr('data-flyout');
		var linkSelector = (flyoutId) ? '.flyout-select[data-flyout=' + flyoutId + ']' : '.flyout-select';

		$flyoutLink = $flyout.siblings(linkSelector);
		if (!$flyoutLink.length) {
			$flyoutLink = $flyoutLink.parent().parent().find(linkSelector);
		}
	},
	openFlyout: function ($flyout) {
		var $flyoutLink = flyouts.getLink($flyout);
		if ($flyoutLink) {
			$flyoutLink.addClass('active');
		}
		flyouts.closeAllFlyouts();
		$flyout.slideDown();
	},
	closeFlyout: function ($flyout) {
		var $flyoutLink = flyouts.getLink($flyout);
		if ($flyoutLink) {
			$flyoutLink.removeClass('active');
		}
		// In this case, hide all instances of this type
		$flyout.slideUp();
	},
	closeAllFlyouts: function () {
		rp$('.flyout[data-flyout]').each(function () {
			flyouts.closeFlyout(rp$(this));
		});
	},
	handleLinkClick: function ($link) {
		var $flyout = this.getFlyout($link);
		if ($flyout.is(':visible')) {
			// $link.removeClass('active');
			// $flyout.slideUp();
			this.closeFlyout($flyout);
		} else {
			// $link.addClass('active');
			// $flyout.slideDown();
			this.openFlyout($flyout);
		}
	},
	handleFlyoutMouseEnter: function ($flyout) {
		var flyoutId = $flyout.attr('data-flyout');
		if (flyoutId) {
			window.clearTimeout(this.timers[flyoutId]);
			this.timers[flyoutId] = 'active';
		}
	},
	handleFlyoutMouseLeave: function ($flyout) {
		var flyoutId = $flyout.attr('data-flyout');
		if (flyoutId) {
			this.timers[flyoutId] = window.setTimeout(function () {
				flyouts.closeFlyout($flyout);
				// In this case, hide all instances of this type
				rp$('.flyout[data-flyout=' + flyoutId + ']').slideUp();
			}, 600);
		}
	},
	attachEvents: function () {
		rp$('.flyout-select').on('click', function () {
			flyouts.handleLinkClick(rp$(this));
		});
		rp$('.flyout').on('click', '.closeicon', function () {
			var $flyout = rp$(this).parent();
			flyouts.closeFlyout($flyout);
		});
		rp$('.flyout').on('mouseenter', function () {
			flyouts.handleFlyoutMouseEnter(rp$(this));
		});
		rp$('.flyout').on('mouseleave', function () {
			flyouts.handleFlyoutMouseLeave(rp$(this));
		});

		rp$('.flyout-select.flyout-hover').each((idx, ele) => {
			let $link = rp$(ele);

			/* Open Flyout
			.*-----------------------------------------*/
			const hoverIn = () => {
				let $flyout = flyouts.getFlyout($link);
				flyouts.openFlyout($flyout);
			};

			/* Close flyout (if flyout is not active)
			.*-----------------------------------------*/
			const hoverOut = () => {
				let $flyout = flyouts.getFlyout($link);
				var flyoutId = $flyout.attr('data-flyout');
				window.setTimeout(() => {
					if (this.timers[flyoutId] !== 'active') {
						flyouts.closeFlyout($flyout);
					}
				}, 100);
			};

			hoverintent(ele, hoverIn, hoverOut).options({
				sensitivity: 7,
				interval: 300
			});
		});
	}
};

module.exports = flyouts;
