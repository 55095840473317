/* global window, gtag, tracking */
'use strict';
const get = require('lodash/get');
const isEmpty = require('lodash/isEmpty');
const debug = require('debug')('webstore:track');
const LocalStorage = require('public/js/lib/local-storage');

const accountGroups = {
	ecEnabled: 'ecEnabled',
	ecDisabled: 'ecDisabled',
	customer: 'customer',
	rp: 'rp',
	default: 'default'
};

const customDimensions = {
	domain: 'Domain',
	hash: 'Store Hash',
	isLoggedIn: 'Logged In User',
	carSelected: 'Car Selected',
	storeType: 'Store Type',
	stockLevelMessaging: 'Stock Level Messaging'
};

const accountIds = {
	rollup: 'rollup',
	virtual: 'virtual',
	rpCust: 'rpcust'
};

const legacyCartEvents = {
	add: 'add',
	remove: 'remove',
	addToWishlist: 'add to wishlist',
};

const gtagEvents = {
	addToCart: 'add_to_cart',
	addToWishlist: 'add_to_wishlist',
	removeFromCart: 'remove_from_cart',
	selectContent: 'select_content',
	viewItem: 'view_item',
	viewItemList: 'view_item_list',
	purchase: 'purchase'
};

const track = {
	debug: false,
	trackers: {},
	transaction: null
};

var _gaq = _gaq || [];

track.init = function () {
	// CUSTOMER ACCOUNTS
	if (tracking.google.analytics_4_ua_numbers) {
		tracking.google.analytics_4_ua_numbers.forEach((trackNumber) => {
			let groups = `default,${accountGroups.customer}`;

			if (tracking.google.enhanced_ecommerce_ga_4_enabled) {
				groups += `,${accountGroups.ecEnabled}`;
			} else {
				groups += `,${accountGroups.ecDisabled}`;
			}

			// Initialize Customer Account (we will manually be sending pageviews)
			gtag('config', trackNumber, {
				'anonymize_ip': true,
				'send_page_view': false,
				'groups': groups
			});
		});
	}


	// RP ACCOUNTS
	if(tracking.google.rp_ga4_numbers) {
		tracking.google.rp_ga4_numbers.forEach((rpTrackNumber) => {
			// Initialize RP Account
			const accountId = rpTrackNumber.id;
			let groups = `default,${accountGroups.rp},${accountId}`;

			// Always enable EC for rollup/virtual. Only enable for rpcust if rp_enhanced_ecommerce_enabled is true.
			if (tracking.google.rp_enhanced_ecommerce_ga_4_enabled || (accountId === 'rollup' || accountId === 'virtual')) {
				groups += `,${accountGroups.ecEnabled}`;
			} else {
				groups += `,${accountGroups.ecDisabled}`;
			}

			gtag('config', rpTrackNumber.ua, {
				'anonymize_ip': true,
				'send_page_view': false,
				'groups': groups,
				'custom_map': this.get_custom_dimensions_map(accountId)
			});
		});
	}

	gtag('set', this.get_custom_dimensions());
};

track.track_event = function (event_args, options, name) {
	let groupName = name;

	// Enhanced Commerce setting is irrelevant for gtag -- based on what is set on Analytics platform
	if (groupName === 'all') {
		groupName = accountGroups.default;
	} else if (groupName === 'ec') {
		groupName = accountGroups.ecEnabled;
	}

	options = options || {};
	if (!options.event_category) {
		options.event_category = event_args[0];
	}
	if (!options.event_action) {
		options.event_action = event_args[1];
	}
	if (!options.event_label) {
		options.event_label = event_args[2];
	}

	if (options.nonInteraction) {
		options.non_interaction = true;
	}

	//const customDimensions = this.get_custom_dimensions();
	//options = {...options, ...customDimensions};
	if (groupName) {
		options.send_to = groupName;
	}

	gtag('event', options.event_action, options);

	debug('track_event', groupName, event_args, options);
};

track.track_transaction = function (name) {
	let groupName = null;

	if (name === 'all' || !name) {
		groupName = accountGroups.ecDisabled;
	}

	let transaction;
	transaction = tracking.transaction;
	if(!transaction) { return; }

	const items = tracking.products.map((item) => ({
		item_id: item.product_id,     // Item ID.
		item_name: item.title,
		item_category: item.category || '',
		price: item.price,
		quantity: item.quantity
	}));

	let options = {
		currency: transaction.currency_code,
		transaction_id: transaction.order_number,  	// Transaction ID.
		value: transaction.total,										// Grand Total.
		affiliation: transaction.affiliation,				// Affiliation or store name.
		shipping: transaction.shipping,							// Shipping.
		tax: transaction.tax, 											// Tax.
		items,
	};

	if (groupName) {
		options.send_to = groupName;
	}

	gtag('event', gtagEvents.purchase, options);

	debug('track_transaction', groupName);
};

track.track_pageview = function (name) {
	const groupName = name === 'all' ? 'default' : name;

	let pageViewDetails = {
		page_location: window.location.href,
		page_path: window.location.pathname,
	};

	if (groupName) {
		pageViewDetails.send_to = groupName;
	}

	if (rp_app.env.google_analytics_enabled) {
		this.do_ec_product_tracking();
		gtag('event', 'page_view', pageViewDetails);
	}
};

track.track_pageview_all_trackers = function() {
	this.track_pageview(accountGroups.default, tracking.page);
};

track.track_ec_impressions = function() {
	const products = tracking.ec.products;
	if (rp_app.env.google_analytics_enabled && Array.isArray(products)) {
		const itemListEventDetails = {
			items: products,
			send_to: accountGroups.ecEnabled
		};

		gtag('event', gtagEvents.viewItemList, itemListEventDetails);
		debug('track_ec_impressions', tracking.ec.products);
	}
};

track.track_ec_cart_event = function(eventName, product_details) {
	if (rp_app.env.google_analytics_enabled) {
		const products = Array.isArray(product_details) ? product_details : [product_details];
		const isProductPage = (tracking.google.page_type === 'product');
		const cartEventDetails = { items: products };
		const gtagEvent = this.get_equivalent_gtag_event(eventName);
		const eventArgs = ['EnhancedEcommerce', gtagEvent, `cart_${eventName}`];
		cartEventDetails.send_to = accountGroups.ecEnabled;

		if (isProductPage) {
			// Add the inventory messaging key if it is set, otherwise use default value
			cartEventDetails[customDimensions.stockLevelMessaging] =
				get(tracking, 'dimensions.stockLevelMessaging', 'NoInventoryMessaging');
		}

		this.track_event(eventArgs, cartEventDetails);

		debug('track_ec_cart_event', eventName, products);
	}
};

track.page_tracking = function (pageType) {
	if(pageType === 'checkout/completed' && get(tracking, 'transaction.track_transaction')) {
		this.track_transaction('all');
	}
};

track.do_ec_product_tracking = function() {
	// If tracking.ec.product_detail is in use, we should be on a product page and so we need to send product details
	if (!isEmpty(tracking.ec.product_detail)) {
		// If tracking.ec.product_click is in use, we should also track the click that got us to the product page
		// NOTE: We want to catch this first so we can send out the click event separately from the details event
		if (!isEmpty(tracking.ec.product_click)) {
			this.track_ec_product_click();
		}

		this.track_ec_product_details();
	}

	// If tracking.ec.products is in use, that means impressions should have occurred for each of those products
	if (!isEmpty(tracking.ec.products)) {
		this.track_ec_impressions();
	}

	// Construct a variety of checks so we are extra-certain we want to do the final purchase analytics
	const transaction_id = get(tracking, 'ec.transaction_details.id');
	const storage_key = `analytics:order_completed:${transaction_id}`;
	const has_tracking_data = (!isEmpty(get(tracking, 'ec.completed_cart_products'))
		&& !isEmpty(get(tracking, 'ec.transaction_details')));
	const is_completed_page = (tracking.page === 'checkout/completed');
	const analytics_completed = (LocalStorage.getItem(storage_key) !== null);
	const track_transaction = get(tracking, 'transaction.track_transaction');
	const should_do_order_completed_analytics = has_tracking_data
		&& is_completed_page
		&& !analytics_completed
		&& track_transaction;

	debug('Pre-purchase analytics checks', {
		has_tracking_data,
		is_completed_page,
		analytics_completed,
		track_transaction,
		should_do_order_completed_analytics,
		storage_key,
	});

	// If all of the checks pass, fire the relevant analytics function
	if (should_do_order_completed_analytics) {
		// KT-2536: Adding some logs here so we can get more information if duplicate order tracking happens
		rp_app.logger.error({
			message: `ORDER_ANALYTICS_TRACKING id: ${transaction_id}`,
			total_quantity: get(tracking, 'transaction.total_quantity'),
			transaction_id: transaction_id,
		});

		this.track_ec_complete_transaction();
		LocalStorage.setItem(storage_key, true);
	}
};

track.track_ec_complete_transaction = function() {
	const items = tracking.ec.completed_cart_products.map((item) => ({
		item_id: item.id,     // Item ID.
		item_name: item.name,
		item_brand: item.brand,
		price: item.price,
		quantity: item.quantity
	}));

	const transactionDetails = get(tracking, 'ec.transaction_details', {});

	const purchaseDetails = {
		currency: transactionDetails.currency ? transactionDetails.currency : 'USD',
		transaction_id: transactionDetails.id,
		value: transactionDetails.revenue,
		shipping: transactionDetails.shipping,
		tax: transactionDetails.tax,
		send_to: accountGroups.ecEnabled,
		items
	};

	// Send the purchase event
	const event_args = ['EnhancedEcommerce', gtagEvents.purchase, 'Purchase'];
	this.track_event(event_args, purchaseDetails);

	debug('track_ec_complete_transaction', tracking.ec.completed_cart_products, tracking.ec.transaction_details);
};

track.track_ec_product_click = function() {
	const list = get(tracking, 'ec.product_click.list');
	const position = get(tracking, 'ec.product_click.position');

	if (list && position) {
		const details = {
			content_type: 'product',
			send_to: accountGroups.ecEnabled,
			non_interaction: true,
			items: [
				{
					...tracking.ec.product_detail,
					list_position: position,
					list_name: list
				}
			]
		};

		// Send the click event
		const event_args = ['EnhancedEcommerce', gtagEvents.selectContent, 'ProductClick'];
		this.track_event(event_args, details);

		debug('track_ec_product_click', tracking.ec.product_click);
	}
};

track.track_ec_product_details = function() {
	const list = get(tracking, 'ec.product_click.list');
	const position = get(tracking, 'ec.product_click.position');

	const details = {
		send_to: accountGroups.ecEnabled,
		items: [
			{
				...tracking.ec.product_detail,
				list_position: position,
				list_name: list
			}
		]
	};

	// Send the details event
	const event_args = ['EnhancedEcommerce', gtagEvents.viewItem, 'ProductDetails'];
	this.track_event(event_args, details);

	debug('track_ec_product_details', tracking.ec.product_detail);
};

track.get_custom_dimensions = function () {
	const storeType = (rp_app.env.is_webstore_plugin) ? 'Webstore_Plugin' : 'Webstore';

	return {
		[customDimensions.domain]: tracking.dimensions.domain,
		[customDimensions.hash]: tracking.dimensions.hash,
		[customDimensions.isLoggedIn]: tracking.dimensions.isLoggedIn,
		[customDimensions.carSelected]: tracking.dimensions.carSelected,
		[customDimensions.storeType]: storeType
	};
};

track.get_custom_dimensions_map = function (accountId) {
	let customDimensionsMap = null;

	switch (accountId) {
		case accountIds.rollup:
		case accountIds.virtual:
			customDimensionsMap = {
				'dimension1': customDimensions.domain,
				'dimension2': customDimensions.hash,
				'dimension3': customDimensions.carSelected,
				'dimension4': customDimensions.isLoggedIn,
				'dimension5': customDimensions.storeType,
				'dimension7': customDimensions.stockLevelMessaging
			};
			break;
		default:
			customDimensionsMap = {};
	}

	return customDimensionsMap;
};

track.get_equivalent_gtag_event = function(eventName) {
	let gtagEventName = null;

	switch (eventName) {
		case legacyCartEvents.add:
			gtagEventName = gtagEvents.addToCart;
			break;
		case legacyCartEvents.addToWishlist:
			gtagEventName = gtagEvents.addToWishlist;
			break;
		case legacyCartEvents.remove:
			gtagEventName = gtagEvents.removeFromCart;
			break;
		default:
			gtagEventName = '';
	}

	return gtagEventName;
};

module.exports = track;
