/* globals document, window */
const get = require('lodash/get');
const isEmpty = require('lodash/isEmpty');

require('core-js/fn/array/from');
require('core-js/fn/array/find');

const constants = Object.freeze({
	ACTIVE_CATEGORY_CLASS: 'active-cat',
});

const searchFacets = {
	constants,
};

searchFacets.clearAllFilters = function () {
	searchFacets.clearFacetCheckboxes(true);
	searchFacets.clearSelectedCategory();
	searchFacets.handleFacetChange(true);
};

searchFacets.getFacetsModule = function () {
	return rp$('.search-facets-module');
};

searchFacets.handleFacetChange = function (useMobileFacets, category = '', identifierName = '', isChecked = false, use_cfc = false) {

	const { facetValues } = searchFacets.getFacetValuesFromDom(useMobileFacets);
	if(!facetValues[identifierName]) {
		if(!isChecked) {
			identifierName = '';
		}
	}

	// Append search str. Append DSEO params if necessary
	const $facetsModule = searchFacets.getFacetsModule();
	const pageId = $facetsModule.data('page-id');
	const pageUrl = $facetsModule.data('page-url');
	const searchStr = $facetsModule.data('search-str');
	if (pageId) { facetValues.page_id = pageId; }
	if (pageUrl) { facetValues.page_url = pageUrl; }
	if (category) { facetValues.category = category; }
	const requestParams = {
		...facetValues,
		search_str: searchStr,
		identifier: {
			identifier: identifierName,
			usePreviousFacets: false
		},
		use_cfc: use_cfc,
	};

	$facetsModule.trigger('selectedFacetBucketsChanged', [requestParams]);
	searchFacets.disableFacets();
};

searchFacets.handleCategoryFacetClick = function (event) {
	event.preventDefault();
	const el = event.target;
	if (!el.classList.contains(constants.ACTIVE_CATEGORY_CLASS)) {
		const facetCategories = searchFacets.getFacetCategories();
		facetCategories.forEach((categoryLink) => categoryLink.classList.remove(constants.ACTIVE_CATEGORY_CLASS));

		el.classList.add(constants.ACTIVE_CATEGORY_CLASS);
		const category_selected = el.href.split('/').pop();

		// The mobile facets shouldn't trigger this on change/click, only when the 'Apply' button is clicked
		if (! el.classList.contains('mobile')) {
			searchFacets.handleFacetChange(false, category_selected);
		}
	}
};

searchFacets.showMoreFacets = function (element){
	const keyWord = element.id.replace('see-more-', '');
	document.querySelectorAll('.hidden-filter-' + keyWord).forEach(function(el) {
		el.removeAttribute('hidden');
	});
	
	document.querySelectorAll('#see-less-' + keyWord).forEach(function(el) {
		el.removeAttribute('hidden');
	});
	element.setAttribute('hidden',true);
};

searchFacets.showLessFacets = function (element){
	const keyWord = element.id.replace('see-less-', '');
	document.querySelectorAll('.hidden-filter-' + keyWord).forEach(function(el) {
		el.setAttribute('hidden', true);
	});
	
	document.querySelectorAll('#see-more-' + keyWord).forEach(function(el) {
		el.removeAttribute('hidden');
	});
	element.setAttribute('hidden', true);
};

searchFacets.getFacetCheckboxes = function() {
	return Array.from(
		document.querySelectorAll('.search-facets-module .multi-select-facet-checkbox.desktop')
	);
};

searchFacets.getMobileFacetCheckboxes = function() {
	return Array.from(
		document.querySelectorAll('.search-facets-module .multi-select-facet-checkbox.mobile')
	);
};

searchFacets.getFacetCategories = function() {
	return Array.from(
		document.querySelectorAll('.customer-friendly-categories .card.cfc .subcategories-list .subcategory')
	);
};

searchFacets.handleParentCategoryExpand = function() {
	const expandCategoryArrows = rp$('.nested-facet-arrow');

	if (!expandCategoryArrows) return;

	expandCategoryArrows.each((_, arrow) => {
		const target = arrow.getAttribute('data-target');

		arrow.addEventListener('click', () => {
			// Show or hide the corresponding sub category section
			const subCategorySection = rp$(`[data-url-name="${target}"]`);
			if (subCategorySection) {
				subCategorySection.each((_, section) => {
					section.toggleAttribute('hidden');
				});
			}

			// Toggle the arrow icon direction
			arrow.classList.toggle('fa-caret-down');
			arrow.classList.toggle('fa-caret-up');
		});
	});
};

searchFacets.prepareFacets = function() {

	const facetsModule = searchFacets.getFacetsModule();
	const multiSelectFacetCheckboxes = searchFacets.getFacetCheckboxes();

	const mobileFiltersApplyButton = rp$('#mobile-filters-apply-button');
	const mobileFiltersClearButton = rp$('#mobile-filters-clear-button');

	if (facetsModule) {

		const showMore =  Array.from(
			document.querySelectorAll('.show-more')
		);
		showMore.forEach((showMoreButton) => {
			showMoreButton.addEventListener('click', () => searchFacets.showMoreFacets(showMoreButton));
		});

		const showLess =  Array.from(
			document.querySelectorAll('.show-less')
		);

		showLess.forEach((showLessButton) => {
			showLessButton.addEventListener('click', () => searchFacets.showLessFacets(showLessButton));
		});

		multiSelectFacetCheckboxes.forEach((checkbox) => {
			checkbox.addEventListener('change', () => searchFacets.handleFacetChange(false, '', checkbox.getAttribute('data-query-name'), checkbox.checked));
		});


		if (mobileFiltersApplyButton && mobileFiltersApplyButton.length) {
			rp$('#mobile-filters-apply-button').on('click', () => { searchFacets.handleFacetChange(true); });
		}

		if (mobileFiltersClearButton && mobileFiltersClearButton.length) {
			rp$('#mobile-filters-clear-button').on('click', () => { searchFacets.clearAllFilters(); });
		}
	}

	searchFacets.handleParentCategoryExpand();
};

searchFacets.getFacetValuesFromDom = function(useMobileFacets = false) {
	const multiSelectFacetCheckboxes = useMobileFacets
		? searchFacets.getMobileFacetCheckboxes()
		: searchFacets.getFacetCheckboxes();

	let identifier = {};

	// Multi-select facet values
	const facetValues = multiSelectFacetCheckboxes.reduce((accumulator, checkbox) => {
		const queryName = checkbox.getAttribute('data-query-name');
		if (!(queryName in accumulator)) {
			accumulator[queryName] = '';
		}

		if (checkbox.checked) {
			const checkboxName = checkbox.getAttribute('name');
			if (!accumulator[queryName]) {
				accumulator[queryName] = checkboxName;
				identifier[queryName] = checkboxName;
			} else {
				accumulator[queryName] = `${accumulator[queryName]},${checkboxName}`;
			}
		}

		return accumulator;
	}, {});

	// Category facet value
	const facetCategories = searchFacets.getFacetCategories();
	const activeCategoryLink = facetCategories.find(
		(categoryLink) => categoryLink.classList.contains(constants.ACTIVE_CATEGORY_CLASS)
	);
	if (activeCategoryLink) {
		const $parentFacetWrapper = rp$(activeCategoryLink).closest('.facet-wrapper');
		if ($parentFacetWrapper.length === 1) {
			const categoryQueryName = $parentFacetWrapper.attr('data-query-name');
			if (categoryQueryName) {
				facetValues[categoryQueryName] = activeCategoryLink.getAttribute('data-cfc-node-id');
			}
		}
	}
	return { facetValues, identifier };
};

searchFacets.getAvailableSearchFacetQueryNames = function() {
	const facets = Array.from(
		document.querySelectorAll('.search-facets-module .facet-wrapper')
	);

	return facets.map((facet) => facet.getAttribute('data-query-name'));
};

searchFacets.updateSelectedFacetCheckboxes = function(selectedFacetData) {
	const selectedFacets = isEmpty(selectedFacetData) ? {} : selectedFacetData;
	const checkboxes = searchFacets.getFacetCheckboxes();

	checkboxes.forEach((checkbox) => {
		const checkboxName = checkbox.getAttribute('name');
		const checkboxQueryName = checkbox.getAttribute('data-query-name');
		const buckets = get(selectedFacets, `[${checkboxQueryName}]`, []);
		const checked = buckets.includes(checkboxName);
		checkbox.checked = checked;
	});
};

searchFacets.clearFacetCheckboxes = function(useMobileFacets = false) {
	const checkboxes = useMobileFacets
		? searchFacets.getMobileFacetCheckboxes()
		: searchFacets.getFacetCheckboxes();

	if (checkboxes && checkboxes.length) {
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
		});
	}
};

searchFacets.clearSelectedCategory = function() {
	const facetCategories = searchFacets.getFacetCategories();
	const activeCategoryLink = facetCategories.find(
		(categoryLink) => categoryLink.classList.contains(constants.ACTIVE_CATEGORY_CLASS)
	);

	if (activeCategoryLink) {
		activeCategoryLink.classList.remove(constants.ACTIVE_CATEGORY_CLASS);
	}
};

function setFacetsInteractiveState(enabled) {
	// Multi select facets
	const checkboxes = searchFacets.getFacetCheckboxes();
	checkboxes.forEach((checkbox) => {
		checkbox.disabled = !enabled;
	});

	// Category facet
	const categoryFacetWrapper = document.querySelector('.facet-wrapper.categories-facet');
	if (categoryFacetWrapper) {
		if (enabled) {
			categoryFacetWrapper.classList.remove('disabled');
		} else {
			categoryFacetWrapper.classList.add('disabled');
		}
	}
}

searchFacets.disableFacets = function() { setFacetsInteractiveState(false); };
searchFacets.enableFacets = function() { setFacetsInteractiveState(true); };

module.exports = searchFacets;
