/* global tracking, gtag */
'use strict';
const get = require('lodash/get');
const GtagTrackingService = require('public/js/lib/gtag-track');

const track = {
	debug: false,
	trackers: {},
	transaction: null,
	trackingServices: [],
};

var _gaq = _gaq || [];

track.init = function () {
	if ((tracking.google.analytics_4_ua_numbers || tracking.google.rp_analytics_4_ua) && typeof gtag === 'function') {
		this.trackingServices.push(GtagTrackingService);
	}
	this.trackServices = (action) => (...props) => {
		this.trackingServices.forEach((trackService) => {
			if (typeof trackService[action] === 'function') {
				trackService[action](...props);
			}
		});
	};
	this.trackServices('init')();
};

track.track_event = function (event_args, options, name) {
	this.trackServices('track_event')(event_args, options, name);
};

track.track_transaction = function (name) {
	this.trackServices('track_transaction')(name);
};

track.track_pageview_all_trackers = function() {
	this.trackServices('track_pageview_all_trackers')();

	if (rp_app.env.google_analytics_enabled) {
		this.rp_track_pageview('rp-reporting', tracking.page || 'other');
	}
};

track.track_ec_impressions = function() {
	this.trackServices('track_ec_impressions')();
};

track.track_ec_cart_event = function(eventName, product_details) {
	this.trackServices('track_ec_cart_event')(eventName, product_details);
};

track.page_tracking = function (pageType) {
	this.trackServices('page_tracking')(pageType);
};

track.rp_track_pageview = function(name, page) {
	if (!tracking['rp-reporting']['enabled']) { return; }

	if (!page || page.indexOf('product') !== 0) { return; }

	const type = get(tracking, 'google.page_type');

	let product = null;
	if (tracking.products) {
		product = tracking.products[0];
	}

	$ajax({
		type: 'POST',
		url: '/ajax/rp-reporting/page-event',
		dataType: 'json',
		data: {
			type: type,
			sku: product ? product.product_id : null,
			catalog: product ? product.catalog : null
		}
	});
};

module.exports = track;
